module.exports = {
	colors: {
		//Primary
		primary: "#3643BA",
		primaryLight1: "#E1E3F5",
		primaryLight2: "#BEDEEF",
		primaryLight3: "#7EBEE0",
		primaryLight4: "#FFFFFF26",
		primaryLight5: "#FFFFFF40",
		primaryDark1: "#323894",
		primaryDark2: "#2D2E70",
		primaryOutline: "#3643BA26",

		//Secondary
		secondary: "#02BE8A",
		secondaryLight1: "#E4F9F3",
		secondaryLight2: "#BEEFE2",
		secondaryLight3: "#7CDFC4",
		secondaryDark1: "#029E73",
		secondaryDark2: "#017F5C",

		//Tertiary
		tertiary: "#FE5800",
		tertiaryLight1: "#FED5BD",
		tertiaryLight2: "#FEAB7B",

		//Price
		price: "#FFEA28",
		priceLight1: "#FFFBC7",
		priceLight2: "#FFF58D",
		priceDark1: "#FFDC28",
		priceDark2: "#FFD228",

		//Clearance
		clearance: "#FFCD4E",
		clearanceLight1: "#FEC9CB",
		clearanceLight2: "#FD9297",

		//Message
		error: "#E63F3F",
		errorLight: "#FEF5F5",
		success: "#31D0AA",
		successLight: "#EAFAF7",
		info: "#F4B740",
		infoLight: "#FFF0D4",

		//Warning
		warning: "#F4B740",

		//Neutral
		white: "#FFFFFF",
		black: "#001018",
		transparent: "transparent",

		//Grey
		greyExtraLight1: "#FCFCFE",
		greyExtraLight2: "#F8F9F9",
		grey: "#F3F5F5",
		greyLight1: "#EBEEEF",
		greyLight2: "#DBDFE0",
		greyLight3: "#B9C0C3",
		greyDark1: "#76767A",
		greyDark2: "#515152",
		greyDark3: "#2E3336",
		greyDark4: "#0C0C22",

		//Purple
		palePurple1: "#EDEBF9",
		palePurple2: "#DBD6F2",
	},
};
