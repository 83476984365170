import React from "react";
import { IconProps } from "react-feather";

const IconLinkedin = ({ size, ...rest }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="IconLinkedin_svg"
			{...rest}
		>
			<path
				d="M1.66602 3.89084C1.66602 3.35457 1.85371 2.91217 2.22908 2.56362C2.60445 2.21505 3.09245 2.04077 3.69304 2.04077C4.28292 2.04077 4.76018 2.21236 5.12483 2.55557C5.5002 2.9095 5.6879 3.37066 5.6879 3.9391C5.6879 4.4539 5.50558 4.88289 5.14092 5.2261C4.76555 5.58003 4.27219 5.75699 3.66087 5.75699H3.64478C3.0549 5.75699 2.57765 5.58003 2.21299 5.2261C1.84834 4.87217 1.66602 4.42708 1.66602 3.89084ZM1.87515 17.9674V7.22095H5.44658V17.9674H1.87515ZM7.42535 17.9674H10.9968V11.9668C10.9968 11.5914 11.0397 11.3018 11.1255 11.098C11.2756 10.7334 11.5035 10.425 11.8092 10.173C12.1149 9.92097 12.4983 9.79495 12.9595 9.79495C14.1607 9.79495 14.7613 10.6047 14.7613 12.2242V17.9674H18.3327V11.8059C18.3327 10.2186 17.9573 9.01471 17.2066 8.19425C16.4558 7.37378 15.4637 6.96355 14.2304 6.96355C12.8468 6.96355 11.769 7.55879 10.9968 8.74927V8.78144H10.9807L10.9968 8.74927V7.22095H7.42535C7.44679 7.56415 7.45752 8.63128 7.45752 10.4224C7.45752 12.2134 7.44679 14.7284 7.42535 17.9674Z"
				fill="white"
			/>
		</svg>
	);
};
export default IconLinkedin;
