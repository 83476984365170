import React from "react";
import { IconProps } from "react-feather";

const IconYoutube = ({ size, ...rest }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="IconYoutube_svg"
			{...rest}
		>
			<path
				fillRule="evenodd"
				d="M16.4927 4.5122C17.2094 4.70475 17.7764 5.27172 17.969 5.98845C18.322 7.29354 18.3327 10 18.3327 10C18.3327 10 18.3327 12.7172 17.9797 14.0116C17.7871 14.7283 17.2201 15.2952 16.5034 15.4878C15.209 15.8408 9.99935 15.8408 9.99935 15.8408C9.99935 15.8408 4.78968 15.8408 3.49528 15.4878C2.77855 15.2952 2.21159 14.7283 2.01903 14.0116C1.66602 12.7065 1.66602 10 1.66602 10C1.66602 10 1.66602 7.29354 2.00833 5.99915C2.20089 5.28241 2.76786 4.71545 3.48459 4.5229C4.77898 4.16987 9.98865 4.15918 9.98865 4.15918C9.98865 4.15918 15.1983 4.15918 16.4927 4.5122ZM12.6523 10L8.33054 12.5032V7.49679L12.6523 10Z"
				fill="white"
			/>
		</svg>
	);
};
export default IconYoutube;
