import React from "react";
import { IconProps } from "react-feather";

const IconSmartSize = ({ size, color }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="https://www.w3.org/2000/svg"
		>
			<path
				xmlns="https://www.w3.org/2000/svg"
				d="M3 8.9024V13.9348C3.43287 14.3148 3.93249 14.6349 4.5 14.8942V11.7174C4.5 11.5073 4.66789 11.337 4.875 11.337C5.08211 11.337 5.25 11.5073 5.25 11.7174V15.1413C5.25 15.157 5.24907 15.1724 5.24726 15.1876C5.91865 15.4117 6.66908 15.5652 7.5 15.6472V14C7.5 13.7899 7.66789 13.6196 7.875 13.6196C8.08211 13.6196 8.25 13.7899 8.25 14V15.7007C8.49376 15.7118 8.74375 15.7174 9 15.7174H10.5V14C10.5 13.7899 10.6679 13.6196 10.875 13.6196C11.0821 13.6196 11.25 13.7899 11.25 14V15.7174H13.5V12.4783C13.5 12.2682 13.6679 12.0978 13.875 12.0978C14.0821 12.0978 14.25 12.2682 14.25 12.4783V15.7174H16.5V14C16.5 13.7899 16.6679 13.6196 16.875 13.6196C17.0821 13.6196 17.25 13.7899 17.25 14V15.7174H19.5V14C19.5 13.7899 19.6679 13.6196 19.875 13.6196C20.0821 13.6196 20.25 13.7899 20.25 14V15.7622C20.522 15.7968 20.7726 15.8515 21 15.9262V10.5815C21 10.0107 20.8357 9.85007 20.2492 9.84944C19.2497 9.84836 15.4997 9.8489 9.00025 9.85105C8.91785 9.85108 8.83855 9.8381 8.76438 9.81411C6.40746 9.79198 4.32438 9.43888 3 8.9024ZM1.50227 7.58928C1.50076 7.57044 1.5 7.55155 1.5 7.53261C1.5 6.27196 4.85786 5.25 9 5.25C13.1421 5.25 16.5 6.27196 16.5 7.53261V8.38167C18.4838 8.38137 19.734 8.38149 20.2508 8.38205C21.6643 8.38357 22.5 9.20015 22.5 10.5815V17.9185C22.5 18.8967 21 18.8967 21 17.9185C21 17.423 20.6347 17.1848 19.5 17.1848H9C5.81628 17.1848 3.37587 16.389 1.71967 14.7688C1.57902 14.6312 1.5 14.4446 1.5 14.25V7.64674C1.5 7.6274 1.50076 7.60824 1.50227 7.58928ZM9 8.29348C10.6569 8.29348 12 7.95282 12 7.53261C12 7.11239 10.6569 6.77174 9 6.77174C7.34315 6.77174 6 7.11239 6 7.53261C6 7.95282 7.34315 8.29348 9 8.29348Z"
				fill={color}
			/>
		</svg>
	);
};
export default IconSmartSize;
