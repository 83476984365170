import React from "react";
import { IconProps } from "react-feather";

const IconBackToTop = ({ size, color, ...rest }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M9.37488 4.09222L4.60849 8.85861L3.72461 7.97472L9.99988 1.69945L16.2752 7.97472L15.3913 8.85861L10.6249 4.09222L10.6249 18L9.37488 18L9.37488 4.09222Z"
				fill={color}
			/>
		</svg>
	);
};
export default IconBackToTop;
