import React from "react";
import { IconProps } from "react-feather";

const IconNotifyMe = ({ size, color, ...rest }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="IconNotifyMe_svg"
			{...rest}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.69121 9.07775L3.28254 5.99168L2.14847 5.09166C2.0409 5.00629 1.97819 4.87647 1.97819 4.73913C1.97819 3.78112 2.75931 3 3.71732 3H12.413C13.3656 3 14.1433 3.77235 14.152 4.72296C14.1522 4.73219 14.1523 4.74142 14.1521 4.75064V11.2609C14.1521 12.2189 13.371 13 12.413 13H2.63036C2.27018 13 1.97819 12.708 1.97819 12.3478C1.97819 11.9876 2.27018 11.6957 2.63036 11.6957H12.413C12.6506 11.6957 12.8478 11.4985 12.8478 11.2609V5.99176L8.4392 9.07775C8.21464 9.23494 7.91576 9.23494 7.69121 9.07775ZM3.37951 4.4674C3.45964 4.36836 3.58191 4.30435 3.71732 4.30435H12.413C12.5484 4.30435 12.6707 4.36838 12.7508 4.46745L8.0652 7.74739L3.37951 4.4674ZM1 7.95653C1 7.59634 1.29199 7.30435 1.65217 7.30435H3.6087C3.96888 7.30435 4.26087 7.59634 4.26087 7.95653C4.26087 8.31671 3.96888 8.6087 3.6087 8.6087H1.65217C1.29199 8.6087 1 8.31671 1 7.95653ZM1 10.2391C1 9.87895 1.29199 9.58696 1.65217 9.58696H4.91304C5.27323 9.58696 5.56522 9.87895 5.56522 10.2391C5.56522 10.5993 5.27323 10.8913 4.91304 10.8913H1.65217C1.29199 10.8913 1 10.5993 1 10.2391Z"
				fill={color}
			/>
		</svg>
	);
};
export default IconNotifyMe;
