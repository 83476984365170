import React, { useEffect, useState } from "react";
import Image, { ImageLoaderProps } from "next/legacy/image";
import isEmpty from "lodash/isEmpty";

import { useResponsiveOnLoad } from "@hooks/useDevice";

import { EImageSlot, ICustomImageProps } from "./custom-images.types";
import { getImageSource, getUrlWithParams } from "@components/CustomImage/custom-images.utils";

import styles from "./custom-image.module.scss";
import axios from "axios";

const CustomImage = ({
	mobile,
	tablet,
	desktop,
	isFromContentful = true,
	hasMixBlend = false,
	className = "",
	hideBlur = false,
	imagePlaceholderUrl = "/images/product-image-placeholder.jpg",
	thumbnailWidth,
	isProductCardImage = false,
	dataLgItemId,
}: ICustomImageProps) => {
	const {
		isLoad,
		devices: { isTablet, isDesktop, isBigScreen },
	} = useResponsiveOnLoad();

	const imageSource = getImageSource({
		mobile,
		tablet,
		desktop,
		isTablet,
		isDesktop,
		isBigScreen,
	});

	const loader = ({ src, width }: ImageLoaderProps) => {
		return getUrlWithParams({
			src,
			width: thumbnailWidth ?? width,
			isFromContentful,
			isProductCardImage,
		});
	};

	const [isBroken, setIsBroken] = useState(false);

	useEffect(() => {
		if (imageSource?.url && !isFromContentful) {
			(async () => {
				try {
					await axios.get(imageSource.url);
				} catch (error) {
					setIsBroken(true);
				}
			})();
		}
	}, []);

	if (!isLoad || !imageSource || isEmpty(imageSource)) {
		return null;
	}

	if (
		!isFromContentful &&
		(!imageSource.url || imageSource.url.includes("image%20not%20available") || isBroken)
	) {
		imageSource.url = imagePlaceholderUrl;
	}

	return (
		<div
			className={`${styles.imgWrapper} ${hasMixBlend ? styles.mixBlend : ""} ${className}`}
			data-testid="CustomImage_wrapper"
		>
			<Image
				alt={imageSource.alt}
				loader={loader}
				src={imageSource.url}
				layout="responsive"
				width={imageSource.initialWidth}
				height={imageSource.initialHeight}
				objectFit="cover"
				sizes={imageSource.slot || EImageSlot.Full}
				priority={imageSource.priority ?? false}
				placeholder={hideBlur ? undefined : "blur"}
				data-lg-item-id={dataLgItemId}
				blurDataURL={
					hideBlur
						? undefined
						: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8/PVrPQAJHgNe7Z9ovAAAAABJRU5ErkJggg=="
				}
			/>
		</div>
	);
};

export default CustomImage;
