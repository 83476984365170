import React, { useState } from "react";
import { TButtonProps } from "@components/Button/types";

import styles from "@components/Button/Button.module.scss";
import Icon, { EIcon } from "@components/Icon/Icon.component";
import { colors } from "@shared/styles/theme";

const ButtonElement = (props: TButtonProps) => {
	const { buttonSize, buttonType, className, children, multiple, ...rest } = props;
	const [displayDropdown, setDisplayDropdown] = useState(false);

	return (
		<button
			className={`${styles[buttonType]} ${styles[buttonSize]} ${className ?? ""} ${
				multiple ? styles.multiple : ""
			}`}
			{...rest}
			onBlur={(e) => {
				if (!e.currentTarget.contains(e.relatedTarget)) {
					setDisplayDropdown(false);
				}
			}}
			onClick={multiple ? () => setDisplayDropdown(!displayDropdown) : rest.onClick}
		>
			<span>{children}</span>
			{multiple && (
				<Icon className={styles.icon} icon={EIcon.ChevronDown} noHover color={colors.white} />
			)}
			{displayDropdown && (
				<div className={styles.dropdown}>
					{multiple?.map(({ label, href }) => (
						<a key={label} href={href} target="_blank" rel="noreferrer">
							{label}
						</a>
					))}
				</div>
			)}
		</button>
	);
};

export default ButtonElement;
