import React from "react";
import { IconProps } from "react-feather";

const IconFacebook = ({ size, ...rest }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="IconFacebook_svg"
			{...rest}
		>
			<path
				d="M18.3327 10.0506C18.3327 5.42008 14.6021 1.66675 9.99935 1.66675C5.39657 1.66675 1.66602 5.42008 1.66602 10.0506C1.66602 14.2362 4.71268 17.7045 8.69713 18.3334V12.4745H6.58157V10.0501H8.69713V8.20341C8.69713 6.1023 9.94102 4.94119 11.8449 4.94119C12.756 4.94119 13.7105 5.10508 13.7105 5.10508V7.16842H12.6588C11.6238 7.16842 11.3016 7.81508 11.3016 8.47842V10.0506H13.6127L13.2432 12.474H11.3016V18.3334C15.286 17.7045 18.3327 14.2362 18.3327 10.0506Z"
				fill="white"
			/>
		</svg>
	);
};
export default IconFacebook;
