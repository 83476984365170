import React from "react";
import { IconProps } from "react-feather";

const IconReturn = ({ size, color }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="IconReturn_svg"
		>
			<g id="return">
				<path
					id="Vector "
					fillRule="evenodd"
					clipRule="evenodd"
					d="M11.4489 14.0833H15.0007V16.5833H7.08398V8.66659H9.58398V12.4025C11.9923 9.21486 15.7285 7.08325 20.0007 7.08325C27.191 7.08325 32.9173 12.8096 32.9173 19.9999C32.9173 27.1903 27.191 32.9166 20.0007 32.9166C12.8103 32.9166 7.08398 27.1903 7.08398 19.9999H9.58398C9.58398 25.8096 14.191 30.4166 20.0007 30.4166C25.8103 30.4166 30.4173 25.8096 30.4173 19.9999C30.4173 14.1903 25.8103 9.58325 20.0007 9.58325C16.5218 9.58325 13.4251 11.3603 11.4489 14.0833Z"
					fill={color}
				/>
			</g>
		</svg>
	);
};

export default IconReturn;
