import React from "react";
import { IconProps } from "react-feather";

const IconBarCode = ({ size, color }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 40 41"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M7.5 15.5C7.5 14.8096 8.05965 14.25 8.75 14.25C9.44035 14.25 10 14.8096 10 15.5V25.5C10 26.1904 9.44035 26.75 8.75 26.75C8.05965 26.75 7.5 26.1904 7.5 25.5V15.5Z"
				fill={color}
			/>
			<path
				d="M11.25 15.5C11.25 14.8096 11.8097 14.25 12.5 14.25C13.1904 14.25 13.75 14.8096 13.75 15.5V25.5C13.75 26.1904 13.1904 26.75 12.5 26.75C11.8097 26.75 11.25 26.1904 11.25 25.5V15.5Z"
				fill={color}
			/>
			<path
				d="M15 14.875C15 14.5299 15.2799 14.25 15.625 14.25C15.9701 14.25 16.25 14.5299 16.25 14.875V26.125C16.25 26.4701 15.9701 26.75 15.625 26.75C15.2799 26.75 15 26.4701 15 26.125V14.875Z"
				fill={color}
			/>
			<path
				d="M17.5 15.5C17.5 14.8096 18.0596 14.25 18.75 14.25C19.4404 14.25 20 14.8096 20 15.5V25.5C20 26.1904 19.4404 26.75 18.75 26.75C18.0596 26.75 17.5 26.1904 17.5 25.5V15.5Z"
				fill={color}
			/>
			<path
				d="M21.25 14.875C21.25 14.5299 21.5299 14.25 21.875 14.25C22.2201 14.25 22.5 14.5299 22.5 14.875V26.125C22.5 26.4701 22.2201 26.75 21.875 26.75C21.5299 26.75 21.25 26.4701 21.25 26.125V14.875Z"
				fill={color}
			/>
			<path
				d="M23.75 14.875C23.75 14.5299 24.0299 14.25 24.375 14.25C24.7201 14.25 25 14.5299 25 14.875V26.125C25 26.4701 24.7201 26.75 24.375 26.75C24.0299 26.75 23.75 26.4701 23.75 26.125V14.875Z"
				fill={color}
			/>
			<path
				d="M26.25 15.5C26.25 14.8096 26.8096 14.25 27.5 14.25C28.1904 14.25 28.75 14.8096 28.75 15.5V25.5C28.75 26.1904 28.1904 26.75 27.5 26.75C26.8096 26.75 26.25 26.1904 26.25 25.5V15.5Z"
				fill={color}
			/>
			<path
				d="M30 15.5C30 14.8096 30.5596 14.25 31.25 14.25C31.9404 14.25 32.5 14.8096 32.5 15.5V25.5C32.5 26.1904 31.9404 26.75 31.25 26.75C30.5596 26.75 30 26.1904 30 25.5V15.5Z"
				fill={color}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M6.25 9.25C4.86929 9.25 3.75 10.3693 3.75 11.75V29.25C3.75 30.6307 4.86929 31.75 6.25 31.75H33.75C35.1307 31.75 36.25 30.6307 36.25 29.25V11.75C36.25 10.3693 35.1307 9.25 33.75 9.25H6.25ZM1.25 11.75C1.25 8.98858 3.48858 6.75 6.25 6.75H33.75C36.5114 6.75 38.75 8.98858 38.75 11.75V29.25C38.75 32.0114 36.5114 34.25 33.75 34.25H6.25C3.48858 34.25 1.25 32.0114 1.25 29.25V11.75Z"
				fill={color}
			/>
		</svg>
	);
};
export default IconBarCode;
