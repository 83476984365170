import { useAbsolute } from "@hooks/useAbsolute";
import useLocale from "@hooks/useLocale";
import { convertDefaultLocale } from "@shared/contentful/contentful.utils";
import { IPropsComponent } from "../type";
import styles from "./Logo.module.scss";
import { getPropsForImages } from "@components/CustomImage/custom-images.utils";
import CustomImage from "@components/CustomImage/CustomImage.component";

const Logo = ({ className = "", ...rest }: IPropsComponent) => {
	const { locale } = useLocale();
	const { absoluteUrl } = useAbsolute("/");

	const mobileProps = getPropsForImages({
		image: {
			url: `/images/${convertDefaultLocale(locale)}/logo-decathlon.svg`,
			description: "logo Decathlon",
			height: 40,
			width: 160,
		},
		slot: "110px",
	});

	const desktopProps = {
		...mobileProps,
		slot: "160px",
	};

	return (
		<div className={className} {...rest}>
			<a href={absoluteUrl} className={styles.logoLink} data-testid="Logo_link">
				<CustomImage
					mobile={mobileProps}
					desktop={desktopProps}
					isFromContentful={false}
					className={styles.logo}
				/>
			</a>
		</div>
	);
};

export default Logo;
