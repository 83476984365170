import React from "react";
import { IconProps } from "react-feather";

const IconFastDelivery = ({ size, color }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="IconFastDelivery_svg"
		>
			<g id="fast-delivery">
				<path
					id="Vector"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M23.7507 9.16675H6.25065V6.66675H23.7507C25.3577 6.66675 26.6673 7.97639 26.6673 9.58341V10.0001H30.8007C31.7687 10.0001 32.6454 10.474 33.1896 11.2474L33.1955 11.2558L36.1424 15.5181C36.4939 16.0184 36.6673 16.6032 36.6673 17.1834V25.4167C36.6673 27.0238 35.3577 28.3334 33.7507 28.3334H33.3182C33.1052 31.1293 30.7675 33.3334 27.9173 33.3334C25.0672 33.3334 22.7294 31.1293 22.5165 28.3334H17.4848C17.2719 31.1293 14.9341 33.3334 12.084 33.3334C9.23382 33.3334 6.89606 31.1293 6.68312 28.3334H6.25065V25.8334H7.08283C7.89952 23.8764 9.83178 22.5001 12.084 22.5001C14.3362 22.5001 16.2684 23.8764 17.0851 25.8334H22.9162C23.2052 25.1407 23.634 24.5208 24.1673 24.0089V9.58341C24.1673 9.3571 23.977 9.16675 23.7507 9.16675ZM26.6673 12.5001V22.6452C27.0686 22.5503 27.4871 22.5001 27.9173 22.5001C30.1695 22.5001 32.1018 23.8764 32.9185 25.8334H33.7507C33.977 25.8334 34.1673 25.6431 34.1673 25.4167V17.1834C34.1673 17.0991 34.1421 17.0194 34.0961 16.9543L34.0891 16.9443L31.1451 12.6861L31.143 12.6833C31.0541 12.5586 30.9316 12.5001 30.8007 12.5001H26.6673ZM13.0007 14.8834H3.33398V12.3834H13.0007V14.8834ZM9.46732 20.0001H3.33398V17.5001H9.46732V20.0001ZM12.084 25.0001C10.4743 25.0001 9.16732 26.3071 9.16732 27.9167C9.16732 29.5264 10.4743 30.8334 12.084 30.8334C13.6936 30.8334 15.0007 29.5264 15.0007 27.9167C15.0007 26.3071 13.6936 25.0001 12.084 25.0001ZM27.9173 25.0001C26.3077 25.0001 25.0007 26.3071 25.0007 27.9167C25.0007 29.5264 26.3077 30.8334 27.9173 30.8334C29.527 30.8334 30.834 29.5264 30.834 27.9167C30.834 26.3071 29.527 25.0001 27.9173 25.0001Z"
					fill={color}
				/>
			</g>
		</svg>
	);
};

export default IconFastDelivery;
