import { defaultLocale, locales } from "@config/languages";
import en from "public/locales/en/common.json";
import fr from "public/locales/fr/common.json";

interface IReplaceTermInTranslation {
	translation?: string;
	genericTerm?: string;
	newTerm?: string;
}

const translate = {
	en,
	fr,
} as { [key: string]: Record<string, string> };

export const t = (key: string, locale = defaultLocale) => {
	if (!locales.includes(locale)) {
		if (process.env.NODE_ENV === "development") return `Locale ${locale} doesn't exist`;
		return translate[defaultLocale][key];
	}

	let translation = translate[locale][key];

	if (locale !== defaultLocale && !translation) translation = translate[defaultLocale][key];

	return translation;
};

export const replaceTermInTranslation = ({
	translation,
	genericTerm,
	newTerm,
}: IReplaceTermInTranslation) => {
	if (!genericTerm || !translation) return "";
	return translation.replace(`[[${genericTerm}]]`, newTerm ?? "");
};
