import React from "react";
import { IconProps } from "react-feather";
import { colors } from "@shared/styles/theme";

const IconUserLoggedIn = ({ size, color, ...rest }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="IconUserLoggedIn_svg"
			{...rest}
		>
			<path
				d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				data-testid="IconUserLoggedIn_path-one"
			/>
			<path
				d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
				data-testid="IconUserLoggedIn_path-two"
			/>
			<circle cx="19" cy="19" r="3.5" fill={colors.primary} stroke="white" />
		</svg>
	);
};

export default IconUserLoggedIn;
