import React from "react";
import { TLinkProps } from "@components/Button/types";

import styles from "@components/Button/Button.module.scss";

const LinkElement = (props: TLinkProps) => {
	if (!props.href) return null;

	const { buttonType, buttonSize, className, children, target, ...rest } = props;

	return (
		<a
			className={`${styles[buttonType]} ${styles[buttonSize]} ${className ?? ""}`}
			target={target || "_self"}
			{...rest}
		>
			<span>{children}</span>
		</a>
	);
};

export default LinkElement;
