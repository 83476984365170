import { isEmpty } from "lodash";

import { EImageSlot, ICustomImage, ICustomImageProps, IImageParams } from "./custom-images.types";

import { IMediaCflData } from "@components/ContentfulComponents/ComponentAsset/type";
import { widths } from "@config/devices";
import { IDevicesResponsive } from "@hooks/useDevice";
import { IProductImage } from "@shared/types/product.types";

interface IArgs {
	image:
		| IMediaCflData
		| (IProductImage & { description?: string })
		| { url: string; description?: string };
	priority?: boolean;
	slot?: EImageSlot | string;
}

export const getPropsForImages = ({
	image,
	priority = false,
	slot = EImageSlot.Full,
}: IArgs): ICustomImage => {
	return {
		url: image?.url ?? "",
		initialWidth: (image as IMediaCflData)?.width || 500,
		initialHeight: (image as IMediaCflData)?.height || 500,
		alt: image?.description ?? "",
		priority,
		slot,
	};
};

export const getImageSource = ({
	mobile,
	tablet,
	desktop,
	isTablet,
	isDesktop,
	isBigScreen,
}: Pick<ICustomImageProps, "mobile" | "tablet" | "desktop"> &
	Omit<IDevicesResponsive, "isMobile">) => {
	if (isDesktop || isBigScreen) {
		return isEmpty(desktop) ? mobile : desktop;
	}
	if (isTablet) {
		return isEmpty(tablet) ? mobile : tablet;
	}
	return mobile;
};

export const getUrlWithParams = ({
	src,
	width,
	isFromContentful,
	isWebp = true,
	isProductCardImage,
}: IImageParams) => {
	const format = isWebp ? "webp" : "jpg";
	const prefix = src.includes("?") ? "&" : "?";
	const url = isProductCardImage ? src.replace(/format=auto/g, `format=${format}`) : src;
	const dimension = `${width}x0`;

	let params;
	if (isFromContentful) {
		params = `${prefix}w=${width}&fm=${format}`;
	} else if (isProductCardImage) {
		params = src.includes("format=")
			? `${prefix}f=${dimension}`
			: `${prefix}f=${dimension}&format=${format}`;
	} else {
		params = `${prefix}f=${dimension}&format=auto`;
	}
	return `${url}${params}`;
};

export const generateSrcSet = ({ src, isFromContentful, isWebp }: Omit<IImageParams, "width">) => {
	if (!src) return;
	return String(
		widths.map((width) => {
			const formattedImageUrl = getUrlWithParams({
				src,
				width,
				isFromContentful,
				isWebp,
			});
			return `${formattedImageUrl} ${width}w`;
		})
	);
};
