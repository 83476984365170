import React from "react";
import { IconProps } from "react-feather";

const IconEnglish = ({ size, color, ...rest }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="https://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M9.8291 12.5566H5.74121V15.6191H10.5195V17H4.01172V7.04688H10.4717V8.44141H5.74121V11.1895H9.8291V12.5566Z"
				fill={color}
			/>
			<path
				d="M19.8232 17H18.0938L13.6572 9.93848V17H11.9277V7.04688H13.6572L18.1074 14.1357V7.04688H19.8232V17Z"
				fill={color}
			/>
		</svg>
	);
};
export default IconEnglish;
