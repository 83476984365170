import { IconProps } from "react-feather";

interface IIconToggle extends IconProps {
	cxEllipse?: string | number;
}

const IconToggle = ({ cxEllipse, ...rest }: IIconToggle) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="40"
			height="19"
			viewBox="0 0 40 19"
			fill="none"
			overflow="visible"
			{...rest}
		>
			<rect y="0.0507812" width="40" height="18.8986" rx="9.44932" x="1.1" />
			<ellipse
				cy="9.5001"
				rx="7.5"
				ry="7.44932"
				fill="white"
				cx={cxEllipse}
				data-testid="IconToggle_ellipse"
			/>
		</svg>
	);
};
export default IconToggle;
