import React from "react";
import { IconProps } from "react-feather";

const IconFrench = ({ size, color, ...rest }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="https://www.w3.org/2000/svg"
			{...rest}
		>
			<path
				d="M9.73535 12.7354H5.72949V16.9531H4V7H10.3232V8.39453H5.72949V11.3545H9.73535V12.7354Z"
				fill={color}
			/>
			<path
				d="M15.3545 13.1182H13.4268V16.9531H11.6973V7H15.1973C16.3457 7 17.2321 7.25749 17.8564 7.77246C18.4808 8.28743 18.793 9.03255 18.793 10.0078C18.793 10.6732 18.6312 11.2314 18.3076 11.6826C17.9886 12.1292 17.542 12.4733 16.9678 12.7148L19.2031 16.8643V16.9531H17.3506L15.3545 13.1182ZM13.4268 11.7305H15.2041C15.7874 11.7305 16.2432 11.5846 16.5713 11.293C16.8994 10.9967 17.0635 10.5934 17.0635 10.083C17.0635 9.5498 16.9108 9.13737 16.6055 8.8457C16.3047 8.55404 15.8535 8.40365 15.252 8.39453H13.4268V11.7305Z"
				fill={color}
			/>
		</svg>
	);
};
export default IconFrench;
