import React from "react";
import { IconProps } from "react-feather";

const IconInstagram = ({ size, ...rest }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="IconInstagram_svg"
			{...rest}
		>
			<path
				d="M10 2.5C7.963 2.5 7.708 2.5085 6.908 2.545C6.1095 2.5815 5.564 2.7085 5.087 2.894C4.587 3.082 4.1335 3.377 3.7585 3.759C3.37711 4.13357 3.08193 4.58675 2.8935 5.087C2.709 5.564 2.5815 6.11 2.545 6.9085C2.509 7.708 2.5 7.9625 2.5 10C2.5 12.0375 2.5085 12.292 2.545 13.092C2.5815 13.8905 2.7085 14.436 2.894 14.913C3.082 15.413 3.377 15.8665 3.759 16.2415C4.13358 16.6229 4.58675 16.9181 5.087 17.1065C5.564 17.2915 6.1095 17.4185 6.908 17.455C7.708 17.4915 7.963 17.5 10 17.5C12.037 17.5 12.292 17.4915 13.092 17.455C13.8905 17.4185 14.436 17.2915 14.913 17.106C15.413 16.918 15.8665 16.623 16.2415 16.241C16.6229 15.8664 16.9181 15.4133 17.1065 14.913C17.2915 14.436 17.4185 13.8905 17.455 13.092C17.4915 12.292 17.5 12.037 17.5 10C17.5 7.963 17.4915 7.708 17.455 6.908C17.4185 6.1095 17.2915 5.564 17.106 5.087C16.9177 4.58653 16.6225 4.13316 16.241 3.7585C15.8664 3.37711 15.4133 3.08193 14.913 2.8935C14.436 2.709 13.89 2.5815 13.0915 2.545C12.292 2.509 12.0375 2.5 10 2.5ZM10 3.8515C12.0025 3.8515 12.24 3.859 13.031 3.895C13.762 3.9285 14.159 4.05 14.4235 4.1535C14.7735 4.289 15.0235 4.452 15.286 4.714C15.5485 4.9765 15.711 5.2265 15.8465 5.5765C15.9495 5.841 16.0715 6.238 16.105 6.969C16.141 7.76 16.1485 7.9975 16.1485 10C16.1485 12.0025 16.141 12.24 16.105 13.031C16.0715 13.762 15.95 14.159 15.8465 14.4235C15.7265 14.7493 15.5349 15.044 15.286 15.286C15.044 15.535 14.7493 15.7265 14.4235 15.8465C14.159 15.9495 13.762 16.0715 13.031 16.105C12.24 16.141 12.003 16.1485 10 16.1485C7.997 16.1485 7.76 16.141 6.969 16.105C6.238 16.0715 5.841 15.95 5.5765 15.8465C5.25073 15.7265 4.956 15.5349 4.714 15.286C4.46509 15.044 4.27356 14.7493 4.1535 14.4235C4.0505 14.159 3.9285 13.762 3.895 13.031C3.859 12.24 3.8515 12.0025 3.8515 10C3.8515 7.9975 3.859 7.76 3.895 6.969C3.9285 6.238 4.05 5.841 4.1535 5.5765C4.289 5.2265 4.452 4.9765 4.714 4.714C4.95597 4.46502 5.2507 4.27349 5.5765 4.1535C5.841 4.0505 6.238 3.9285 6.969 3.895C7.76 3.859 7.9975 3.8515 10 3.8515Z"
				fill="white"
			/>
			<path
				d="M9.99953 12.5026C9.6709 12.5026 9.34548 12.4378 9.04187 12.3121C8.73825 12.1863 8.46237 12.002 8.23 11.7696C7.99762 11.5372 7.81328 11.2614 7.68752 10.9577C7.56176 10.6541 7.49703 10.3287 7.49703 10.0001C7.49703 9.67144 7.56176 9.34602 7.68752 9.0424C7.81328 8.73879 7.99762 8.46291 8.23 8.23053C8.46237 7.99816 8.73825 7.81382 9.04187 7.68806C9.34548 7.5623 9.6709 7.49757 9.99953 7.49757C10.6632 7.49757 11.2998 7.76122 11.7691 8.23053C12.2384 8.69984 12.502 9.33636 12.502 10.0001C12.502 10.6638 12.2384 11.3003 11.7691 11.7696C11.2998 12.2389 10.6632 12.5026 9.99953 12.5026ZM9.99953 6.14507C8.97712 6.14507 7.99659 6.55122 7.27363 7.27417C6.55068 7.99712 6.14453 8.97766 6.14453 10.0001C6.14453 11.0225 6.55068 12.003 7.27363 12.726C7.99659 13.4489 8.97712 13.8551 9.99953 13.8551C11.0219 13.8551 12.0025 13.4489 12.7254 12.726C13.4484 12.003 13.8545 11.0225 13.8545 10.0001C13.8545 8.97766 13.4484 7.99712 12.7254 7.27417C12.0025 6.55122 11.0219 6.14507 9.99953 6.14507ZM14.976 6.07507C14.976 6.31675 14.88 6.54853 14.7091 6.71942C14.5382 6.89031 14.3065 6.98632 14.0648 6.98632C13.8231 6.98632 13.5913 6.89031 13.4204 6.71942C13.2495 6.54853 13.1535 6.31675 13.1535 6.07507C13.1535 5.83339 13.2495 5.60161 13.4204 5.43072C13.5913 5.25982 13.8231 5.16382 14.0648 5.16382C14.3065 5.16382 14.5382 5.25982 14.7091 5.43072C14.88 5.60161 14.976 5.83339 14.976 6.07507Z"
				fill="white"
			/>
		</svg>
	);
};
export default IconInstagram;
