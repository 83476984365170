import { useEffect, useState } from "react";
import { deviceQuerySizes } from "@config/devices";

const { mobile, tablet, desktop, bigScreen } = deviceQuerySizes;

export interface IDevicesResponsive {
	isMobile: boolean;
	isTablet: boolean;
	isDesktop: boolean;
	isBigScreen: boolean;
}

export const useResponsive = (): IDevicesResponsive => {
	const defaultValues: IDevicesResponsive = {
		isMobile: false,
		isTablet: false,
		isDesktop: true,
		isBigScreen: false,
	};

	const [responsiveState, setResponsiveState] = useState<IDevicesResponsive>(defaultValues);

	useEffect(() => {
		if (!window) return;
		const handleResize = () => {
			const width = window.innerWidth;
			const isMobile = width <= mobile.max;
			const isTablet = width >= tablet.min && width < desktop.min;
			const isDesktop = width >= desktop.min && width < bigScreen.min;
			const isBigScreen = width >= bigScreen.min;

			setResponsiveState({ isMobile, isTablet, isDesktop, isBigScreen });
		};

		handleResize();

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	if (typeof window === "undefined") {
		return defaultValues;
	}

	return responsiveState;
};

export const useResponsiveOnLoad = () => {
	const [isLoaded, setIsLoaded] = useState(false);
	const devices = useResponsive();

	useEffect(() => {
		if (typeof window !== "undefined") {
			setIsLoaded(true);
		}
	}, [isLoaded]);

	return { isLoad: isLoaded, devices };
};
