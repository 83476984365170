import { ImageLoaderProps } from "next/legacy/image";

export enum EImageSlot {
	Full = "100vw",
	Half = "50vw",
	Third = "30vw",
	Fourth = "25vw",
	Sixth = "16.5vw",
}

export type ICustomImage = {
	url: string;
	initialWidth: number;
	initialHeight: number;
	alt: string;
	slot?: EImageSlot | string;
	priority?: boolean;
};

export interface ICustomImageProps {
	isFromContentful?: boolean;
	mobile?: ICustomImage | null;
	tablet?: ICustomImage | null;
	desktop?: ICustomImage | null;
	className?: string;
	hasMixBlend?: boolean;
	hideBlur?: boolean;
	loading?: boolean;
	priority?: boolean;
	imagePlaceholderUrl?: string;
	thumbnailWidth?: number;
	isProductCardImage?: boolean;
	dataLgItemId?: number;
}

export interface IImageParams extends Pick<ImageLoaderProps, "src" | "width"> {
	isFromContentful: boolean;
	isWebp?: boolean;
	isProductCardImage?: boolean;
}
