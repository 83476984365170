import React from "react";
import { IconProps } from "react-feather";

const IconTikTok = ({ size, ...rest }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="IconTikTok_svg"
			{...rest}
		>
			<path
				d="M13.6813 1.66675H10.804V13.0194C10.804 14.3721 9.69733 15.4832 8.32015 15.4832C6.94296 15.4832 5.8363 14.3721 5.8363 13.0194C5.8363 11.6909 6.91837 10.6039 8.24638 10.5556V7.70541C5.31986 7.75369 2.95898 10.0967 2.95898 13.0194C2.95898 15.9663 5.36905 18.3334 8.34475 18.3334C11.3204 18.3334 13.7305 15.9421 13.7305 13.0194V7.19814C14.8126 7.9711 16.1405 8.43004 17.5423 8.4542V5.60395C15.3782 5.53148 13.6813 3.79235 13.6813 1.66675Z"
				fill="white"
			/>
		</svg>
	);
};
export default IconTikTok;
