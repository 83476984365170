import React from "react";
import { IconProps } from "react-feather";

const IconQuestion = ({ size, color }: IconProps) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 40 40"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="IconQuestion_svg"
		>
			<g id="question">
				<g id="Vector">
					<path
						fillRule="evenodd"
						clipRule="evenodd"
						d="M19.9993 5.91626C12.2213 5.91626 5.91602 12.2216 5.91602 19.9996C5.91602 27.7776 12.2213 34.0829 19.9993 34.0829C27.7774 34.0829 34.0827 27.7776 34.0827 19.9996C34.0827 12.2216 27.7774 5.91626 19.9993 5.91626ZM3.41602 19.9996C3.41602 10.8409 10.8406 3.41626 19.9993 3.41626C29.1581 3.41626 36.5827 10.8409 36.5827 19.9996C36.5827 29.1583 29.1581 36.5829 19.9993 36.5829C10.8406 36.5829 3.41602 29.1583 3.41602 19.9996ZM14.4197 16.5704C14.6346 13.7767 16.9889 11.4163 19.9993 11.4163C23.023 11.4163 25.5827 13.9759 25.5827 16.9996C25.5827 18.3694 24.9224 19.8443 23.8832 20.8835C23.4008 21.3659 22.8327 21.717 22.3987 21.9852C22.3454 22.0182 22.2941 22.0499 22.2452 22.0804C21.7556 22.3864 21.4054 22.6315 21.1263 22.9665L21.09 23.0101L21.0499 23.0501C20.7167 23.3834 20.5827 23.825 20.5827 24.4996H18.0827C18.0827 23.5211 18.2766 22.3159 19.2417 21.3234C19.7879 20.6829 20.4208 20.2725 20.9202 19.9604C20.9705 19.929 21.0191 19.8987 21.0662 19.8694C21.5368 19.5762 21.8504 19.3808 22.1155 19.1157C22.7429 18.4882 23.0827 17.6298 23.0827 16.9996C23.0827 15.3566 21.6423 13.9163 19.9993 13.9163C18.3588 13.9163 17.0556 15.1979 16.916 16.7185V17.3315L14.416 17.3329V16.6183L14.4197 16.5704Z"
						fill={color}
					/>
					<path
						d="M19.3327 29.1663C20.1612 29.1663 20.8327 28.4947 20.8327 27.6663C20.8327 26.8378 20.1612 26.1663 19.3327 26.1663C18.5043 26.1663 17.8327 26.8378 17.8327 27.6663C17.8327 28.4947 18.5043 29.1663 19.3327 29.1663Z"
						fill={color}
					/>
				</g>
			</g>
		</svg>
	);
};

export default IconQuestion;
