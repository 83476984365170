import { ButtonHTMLAttributes, LinkHTMLAttributes, ReactNode } from "react";

export enum EButtonType {
	Primary = "btnPrimary",
	PrimaryLight = "btnPrimaryLight",
	Secondary = "btnSecondary",
}

export enum EButtonSize {
	Sm = "btnSm",
	Md = "btnMd",
	Lg = "btnLg",
}

interface IGenericButton {
	as: keyof Pick<JSX.IntrinsicElements, "a" | "button">;
	children: ReactNode;
	buttonType: EButtonType;
	buttonSize: EButtonSize;
	multiple?: {
		label: string;
		href: string;
	}[];
}

export type TButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & IGenericButton;

export type TLinkProps = LinkHTMLAttributes<HTMLAnchorElement> &
	IGenericButton & {
		target?: string;
	};
