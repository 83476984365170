import { useEffect, useState } from "react";

import useLocale from "./useLocale";
import { isExternal } from "@utils/links.utils";
import { removeLeadingSlash, removeTrailingSlash } from "@utils/url.utils";

export const useAbsolute = (url: string, lang?: string) => {
	const [absoluteUrl, setAbsoluteUrl] = useState("");
	const { locale } = useLocale();
	const errorMessage = "an url must be provided";
	useEffect(() => {
		if (!url) {
			console.error(errorMessage);
		}
	}, []);

	useEffect(() => {
		const langUrl = lang || locale;
		if (!isExternal(url)) {
			const nakedUrl = removeLeadingSlash(url);
			setAbsoluteUrl(removeTrailingSlash(`${window.location.origin}/${langUrl}/${nakedUrl}`));
		} else {
			setAbsoluteUrl(url);
		}
	}, [url]);

	return { absoluteUrl };
};
