const deviceSizes = [16, 32, 48, 64, 96, 128, 256, 320];
const imageSizes = [
	380, 472, 576, 640, 750, 828, 1080, 1200, 1440, 1680, 1800, 1920, 2048, 2520, 3000, 3250, 3620,
	3840,
];

const deviceQuerySizes = {
	mobile: { max: 600 },
	tablet: { min: 601 },
	desktop: { min: 1024 },
	bigScreen: { min: 1440 },
	ultraWide: { min: 1920 },
};

module.exports = {
	screens: {
		sm: { max: `${deviceQuerySizes.mobile.max}px` },
		md: { min: `${deviceQuerySizes.tablet.min}px` },
		lg: { min: `${deviceQuerySizes.desktop.min}px` },
		xl: { min: `${deviceQuerySizes.bigScreen.min}px` },
		xxl: { min: `${deviceQuerySizes.ultraWide.min}px` },
	},
	widths: [...deviceSizes, ...imageSizes],
	deviceSizes,
	imageSizes,
	deviceQuerySizes,
};
