import React from "react";
import { TButtonProps, TLinkProps } from "./types";
import ButtonElement from "@components/Button/ButtonElement/ButtonElement.component";
import LinkElement from "@components/Button/LinkElement/LinkElement.component";
import { isButtonElement, isLinkElement } from "@components/Button/utils";

const Button = (props: TButtonProps | TLinkProps) => {
	const propsElement = { ...props };
	if ("disabled" in propsElement && propsElement.disabled) propsElement.as = "button";

	if (isButtonElement(propsElement)) return <ButtonElement {...propsElement} />;
	if (isLinkElement(propsElement)) return <LinkElement {...propsElement} />;
	return null;
};

export default Button;
